import { Component, inject, input, output } from "@angular/core";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'button-cancel',
  standalone: true,
  imports: [],
  template: `
    <button mat-dialog-close (click)="cancel.emit($event)">{{ label() }}</button>
  `,
  styles: `
  :host button {
    cursor: pointer;
    background: none;
    border: none;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
  }
  `
})
export class ButtonCancelComponent {
  label = input<string>('Cancel')
  cancel = output<Event>()
}
